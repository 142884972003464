import React from "react"
import Layout from "../../components/modules/Layout"
import BodyBlocks from "../../components/technical/BodyBlocks"

const kickerPlaceholder = "Ein Platzhalter-Kicker"
const headlinePlaceholder = "Eine Platzhalter-Überschrift"
const subheadlinePlaceholder = "Eine Platzhalter-Unterzeile"
const textPlaceholder =
  "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken."
const buttonsPlaceholder = [
  {
    link_text: "Primär Button",
    link: "/",
  },
  {
    link_text: "Sekundär Button",
    link: "/",
  },
]
const imagePlaceholder = {
  filename:
    "https://a.storyblok.com/f/108683/1920x1080/d6600d2022/bg_placeholder_1080p_center.jpg",
}
const videoPlaceholder = "https://www.youtube.com/watch?v=8o-JI8VRyKo"

const BasicPage = () => {
  const body = [
    {
      component: "anchor_block",
      target_hash: "anchor-test",
    },
    {
      component: "hero_block",
      kicker: kickerPlaceholder,
      headline: "Hero Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      image: imagePlaceholder,
    },
    {
      component: "text_block",
      kicker: kickerPlaceholder,
      headline: "Text Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      text: textPlaceholder,
      buttons: buttonsPlaceholder,
    },
    {
      component: "quote_block",
      quote: "Zitat Block: Faber est suae quisque fortunae",
      image: imagePlaceholder,
      author: "Appius Claudius Caecus",
      author_description: "Staatsmann der Römischen Republik",
    },
    {
      component: "infobox_block",
      kicker: kickerPlaceholder,
      headline: "Infobox Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      text: textPlaceholder,
      buttons: buttonsPlaceholder,
    },
    {
      component: "text_video_block",
      kicker: kickerPlaceholder,
      headline: "Text mit Video Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      text: textPlaceholder,
      buttons: buttonsPlaceholder,
      video_url: videoPlaceholder,
    },
    {
      component: "text_image_block",
      kicker: kickerPlaceholder,
      headline: "Text mit Bild Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      text: textPlaceholder,
      buttons: buttonsPlaceholder,
      image: imagePlaceholder,
    },
    {
      component: "cta_block",
      kicker: kickerPlaceholder,
      headline: "CallToAction Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      text: textPlaceholder,
      buttons: buttonsPlaceholder,
      image: imagePlaceholder,
    },
    {
      component: "richlink_block",
      title: headlinePlaceholder,
      description: textPlaceholder,
      link: "/",
      link_text: "Ein Link",
      image: imagePlaceholder,
    },
    {
      component: "table_block",
      kicker: kickerPlaceholder,
      headline: "Table Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      table: {
        thead: [
          { value: "1. Spalten Überschrift" },
          { value: "2. Spalten Überschrift" },
        ],
        tbody: [
          {
            body: [
              {
                value: "Zelle 1/1: Markdown **Fettung**",
              },
              {
                value: "Zelle 1/2",
              },
            ],
          },
          {
            body: [
              {
                value: "Zelle 2/1",
              },
              {
                value: "Zelle 2/2",
              },
            ],
          },
        ],
      },
    },
    {
      component: "table_block",
      kicker: kickerPlaceholder,
      headline: "Table Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      markdown:
        "| 1. Spalten Überschrift | 2. Spalten Überschrift  |\n | ----- | ------- |\n | Zelle 1/1: Markdown **Fettung**  | Zelle 1/2: Markdown-[Link](https://google.com) |\n| Zelle 2/1 | Zelle 2/2 |",
    },
  ]

  return (
    <Layout>
      <BodyBlocks body={body} />
    </Layout>
  )
}

export default BasicPage
